import { useAppAlerts } from '@/stores/app/alerts';
import { isEmpty, camelCase } from 'lodash-es';
import { paramCleaner } from '@/helpers/formatters';
import { getQuarter } from '@/helpers/date';
import { format } from 'date-fns';
import { postRequest } from './request';

export const getDemographicSurveyReport = async ({
  selectedDateRange = {}, routeQuery = {}, filters = [], selectedMyJobs,
}) => {
  const responseData = {};
  const failedRequests = [];
  const responseDefaultValue = {
    filters: {},
    genderBreakdown: {},
    raceBreakdown: {},
    genderByStage: {},
    raceByStage: {},
    reqFillRate: {},
    sourceFillRate: {},
    authorized: false,
  };

  let unauthorized = false;

  const fetchBatch = async (batch, body) => {
    const promises = batch.map(({ path, params = {} }) => {
      const queryParams = new URLSearchParams(params).toString();

      return postRequest({ url: `/eeo/${path}?${queryParams}`, body });
    });
    const results = await Promise.allSettled(promises);

    results.forEach((response, i) => {
      const { path, label, defaultValue } = batch[i];
      const formattedPath = camelCase(path);

      // Create the response object
      if (response.status === 'fulfilled') {
        responseData[formattedPath] = response.value;
        return;
      }

      if (response.reason?.response?.status === 403) {
        unauthorized = true;
      } else {
        failedRequests.push(label);
      }

      responseData[formattedPath] = defaultValue;
    });
  };

  const {
    to: queryTo, from: queryFrom, owned: queryOwned, tab, ...paramFilters
  } = routeQuery;

  const cleanedParams = paramCleaner(paramFilters);

  const { to = queryTo, from = queryFrom } = selectedDateRange;

  const searchParams = new URLSearchParams();

  if (typeof to !== 'undefined') searchParams.append('to', to);
  else searchParams.append('to', format(getQuarter(1)[1], 'yyyy-MM-dd'));

  if (typeof from !== 'undefined') searchParams.append('from', from);
  else searchParams.append('from', format(getQuarter(1)[0], 'yyyy-MM-dd'));

  const batches = [
    [
      { path: 'filters', label: 'filters', defaulValue: {} },
      { path: 'gender-breakdown', label: 'gender breakdown', defaultValue: {} },
      { path: 'race-breakdown', label: 'race breakdown', defaultValue: {} },
    ],
    [
      { path: 'gender-by-stage', label: 'gender breakdown by stage', defaultValue: {} },
      { path: 'race-by-stage', label: 'race breakdown by stage', defaultValue: {} },
      {
        path: 'req-fill-rate', label: 'requisition fill rate', defaultValue: {}, params: { page: 1, sort: 'fill_rate' },
      },
      {
        path: 'source-fill-rate', label: 'source fill rate', defaultValue: {},
      },
    ],
  ];

  const body = {
    filters: !isEmpty(filters) ? filters : cleanedParams,
    dateRange: selectedDateRange,
    owned: selectedMyJobs,
  };

  await fetchBatch(batches[0], body);
  await fetchBatch(batches[1], body);

  if (unauthorized) {
    useAppAlerts().addAlert({
      type: 'error',
      message: 'You do not have permission to view this report.',
    });

    return responseDefaultValue;
  }

  if (failedRequests.length) {
    useAppAlerts().addAlert({
      type: 'error',
      message: `There was an error fetching the diversity report's ${failedRequests.join(', ')}`,
    });
  }

  responseData.authorized = true;

  return responseData;
};

export const loadReqFillRate = async ({
  selectedDateRange, routeQuery, filters, page = 1, sort = 'fill_rate',
}) => {
  const { to, from } = selectedDateRange;
  const {
    owned, from: routeFrom, to: routeTo, tab, ...paramFilters
  } = routeQuery;

  const cleanedParams = paramCleaner(paramFilters);

  const searchParams = new URLSearchParams();

  searchParams.append('page', page);

  if (typeof sort !== 'undefined') searchParams.append('sort', sort);

  const body = {
    filters: !isEmpty(filters) ? filters : cleanedParams,
    dateRange: { to, from },
  };

  return postRequest({
    url: `/eeo/req-fill-rate?${searchParams.toString()}`,
    body,
  });
};
