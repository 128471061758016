import { defineStore, storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import {
  getDashboardsPage,
  startReportingFreeTrial,
  sendDashboardShareLink,
} from '@/api/insights';
import { startCase, isEqual, isEmpty } from 'lodash-es';
import { removeEmptyParams } from '@/stores/filters/helpers';
import { getQuarter } from '@/helpers/date';
import { useAppLoading } from '@/stores/app/loading';
import { useAppAlerts } from '@/stores/app/alerts';
import { useModals } from '@/stores/modals/modals';
import { useUsage } from '@/stores/insights/usage';
import { useActionableInsights } from '@/stores/insights/actionableInsights';
import { useExecutiveInsights } from '@/stores/insights/executiveInsights';
import { useDemographicSurveyReport } from '@/stores/insights/demographicSurveyReport';
import { getSafeError, dashboardsPages, isError } from '@/stores/insights/helpers';
import { useInsightsShareForm } from '@/stores/insights/insightsShareForm';
import { useAppRoute } from '@/stores/app/route';
import { getWidgets, getPageLabelsForLoading } from '@/api/helpers/insights/insights';
import { useWeeklySnapshot } from '@/stores/insights/weeklySnapshot';
import { useContentSnapshot } from '@/stores/insights/contentSnapshot';
import { useLanguageSnapshot } from '@/stores/insights/languageSnapshot';
import { useFilters } from '@/stores/filters/filters';
import { format } from 'date-fns';
import { useRecruitingPerformance } from './recruitingPerformance';
import { useInsightsPermissions } from './insightsPermissions';

let navigatedToDefaultExternalPageViaHistory = false;

// Triggers on back/forward button press
window.addEventListener('popstate', () => {
  const defaultExternalPages = [
    '/app/insights/compliance-report',
    '/app/insights/easy-wins-report',
    '/app/insights/confidence-gap-report',
    '/app/insights/usage',
  ];

  if (defaultExternalPages.includes(window.location.pathname)) {
    navigatedToDefaultExternalPageViaHistory = true;
  }
});

export const useInsights = defineStore('insights', () => {
  // State
  const labelsForLoading = ref({});
  const reportingMessage = ref({});
  const dismissTrialBanner = ref(false);
  const widgets = ref([]);
  const tables = ref({});
  const totals = ref({});
  const selectedTable = ref(null);
  const labelFormats = ref([]);
  const displayOptions = ref({});
  const flags = ref({});
  const emailStatus = ref({
    succeeded: {
      type: 'success',
      message: 'One or more emails have been sent successfully',
      tooltipMessage: 'Emails sent to:',
      items: [],
    },
    failed: {
      type: 'error',
      message: 'One or more emails failed to send',
      tooltipMessage: 'Failed to send to:',
      items: [],
    },
  });
  const showInternalDefault = ref(true);

  // Getters
  const widgetsWithClasses = computed(() => {
    let smallIndex = 0;
    const newWidgets = [];

    // Only add margin to every other small widget
    widgets.value.forEach((widget) => {
      if (widget?.size === 'large') {
        newWidgets.push({ ...widget, className: 'large-widget', error: widget.error || isError(widget) });
      } else if (smallIndex % 2 > 0) {
        newWidgets.push({ ...widget, className: 'small-widget', error: widget.error || isError(widget) });
        smallIndex++;
      } else {
        newWidgets.push({ ...widget, className: 'small-widget-with-margin', error: widget.error || isError(widget) });
        smallIndex++;
      }
    });
    return newWidgets;
  });

  const checkDatasets = (dataset) => {
    const keys = Object.keys(dataset);

    return keys.every((key) => !(dataset?.[key]?.value || dataset?.[key]?.percentage));
  };

  const emptyTableData = computed(() => {
    const topLevelEmpty = isEmpty(tables.value || {});
    const tablesReturnedWithoutData = isEmpty(tables.value?.firstTable?.data || {})
      && isEmpty(tables.value?.secondTable?.data || {});
    const tablesValuesAndPercentagesAreFalsey = (tables.value?.firstTable?.data || [])
      .some((dataset) => {
        const keys = Object.keys(dataset);

        if (keys.length === 2 && keys.includes('label') && keys.includes('items')) {
          if (!dataset.items.length) return true;

          return dataset.items.some((nestedDataset) => checkDatasets(nestedDataset));
        }

        return checkDatasets(dataset);
      });

    return topLevelEmpty || tablesReturnedWithoutData || tablesValuesAndPercentagesAreFalsey;
  });

  const emptyWidgetData = computed(() => {
    const routeStore = useAppRoute();
    const { route } = storeToRefs(routeStore);

    if (route.value?.name === 'executive-insights') {
      const executiveInsightsStore = useExecutiveInsights();
      const { isExecutiveInsightsEmpty } = storeToRefs(executiveInsightsStore);

      return isExecutiveInsightsEmpty.value;
    }

    return widgetsWithClasses.value
      .every(({ values }) => !values.length || values.every((value) => !value));
  });

  const emptyData = computed(() => {
    const routeStore = useAppRoute();
    const { route } = storeToRefs(routeStore);

    if (route.value?.name === 'recruiting-performance') {
      const recruitingPerformanceStore = useRecruitingPerformance();
      const { summary } = storeToRefs(recruitingPerformanceStore);
      const { table = [] } = summary.value;

      return !table.length;
    }

    if (route.value?.name === 'weekly-snapshot') {
      const weeklySnapshotStore = useWeeklySnapshot();
      const { documents } = storeToRefs(weeklySnapshotStore);

      return !documents.value?.length;
    }

    if (route.value?.name === 'content-snapshot') {
      const contentSnapshotStore = useContentSnapshot();
      const { contentStats } = storeToRefs(contentSnapshotStore);

      return !Object.keys(contentStats.value || {}).length;
    }

    if (route.value?.name === 'language-snapshot') {
      const languageSnapshotStore = useLanguageSnapshot();
      const { languageBlocks } = storeToRefs(languageSnapshotStore);

      return !languageBlocks.value?.length;
    }

    if (route.value?.name === 'usage') {
      const usageStore = useUsage();
      const { jobs } = storeToRefs(usageStore);

      return !jobs.value?.length;
    }

    if (['compliance-report', 'easy-wins-report', 'confidence-gap-report'].includes(route.value?.name)) {
      const actionableInsightsStore = useActionableInsights();
      const { documents } = storeToRefs(actionableInsightsStore);

      return !documents.value?.length;
    }

    if (route.value?.name === 'demographic-survey-report') {
      const demographicSurveyStore = useDemographicSurveyReport();
      const { isEmptyData } = storeToRefs(demographicSurveyStore);

      return isEmptyData.value;
    }

    return emptyWidgetData.value && emptyTableData.value;
  });

  // Synchronous Actions
  const setLabelsForLoading = (value) => {
    labelsForLoading.value = value;
  };

  const setReportingMessage = (message) => {
    reportingMessage.value = { ...message };
  };

  const setDismissTrialBanner = (value) => {
    dismissTrialBanner.value = value;
  };

  const setWidgets = (value) => {
    widgets.value = value;
  };

  const setTables = (value) => {
    tables.value = value;
  };

  const setTotals = (value) => {
    totals.value = value;
  };

  const setLabelFormats = (value) => {
    labelFormats.value = value;
  };

  const setDisplayOptions = (value) => {
    displayOptions.value = value;
  };

  const resetDashboardsPage = () => {
    totals.value = {};
    widgets.value = [];
  };

  const setSelectedTable = (value) => {
    selectedTable.value = value;
  };

  const setFlags = (value) => {
    flags.value = value;
  };

  const updateEmails = ({ succeeded, failed }) => {
    emailStatus.value.failed = {
      ...emailStatus.value.failed,
      items: failed,
    };
    emailStatus.value.succeeded = {
      ...emailStatus.value.succeeded,
      items: succeeded,
    };
  };

  const setShowInternalDefault = (value) => {
    showInternalDefault.value = value;
  };

  // Asynchronous Actions
  const loadDashboardsPageData = async ({
    page,
    selectedDateRange,
    routeQuery,
    router,
    selectedFilters,
    selectedMyJobs,
  }) => {
    const routeStore = useAppRoute();
    const { route } = storeToRefs(routeStore);
    const lastQuarter = getQuarter(1);
    const fallbackDateRange = selectedDateRange || { from: format(lastQuarter[0], 'yyyy-MM-dd'), to: format(lastQuarter[1], 'yyyy-MM-dd') };
    const date = routeQuery?.from && routeQuery?.to
      ? { from: routeQuery.from, to: routeQuery.to }
      : fallbackDateRange;

    const skeletonLoadersTimeout = setTimeout(() => {
      const labels = getPageLabelsForLoading(page);
      setLabelsForLoading(labels);
      // add intermediate loading state to filter bar
      useAppLoading().setLoading({ halfTopBarLoading: false });
    }, 4000);

    useAppLoading().setLoading({
      pageLoading: true, filtersLoading: true, halfTopBarLoading: true,
    });

    const {
      chartData = {},
      filters = [],
      owned = {},
      tableData = [],
      dateRange,
      metrics = {},
      flags: newFlags = {},
    } = await getDashboardsPage({
      page, selectedDateRange: date, routeQuery, filters: selectedFilters, owned: selectedMyJobs,
    });

    const {
      widgets: newWidgets = [],
      totals: newTotals = {},
      labelFormats: newLabelFormats = [],
      tables: newTables = {},
      displayOptions: newDisplayOptions = {},
    } = getWidgets({ chartData, tableData, metrics }, page);

    clearTimeout(skeletonLoadersTimeout);
    setLabelsForLoading({});

    /* Send relevant updates to filters */
    useFilters().updateFilters({
      dateRange,
      filters,
      myJobsEnabled: owned,
    });

    setWidgets(newWidgets);
    setTotals(newTotals);
    setLabelFormats(newLabelFormats);
    setDisplayOptions(newDisplayOptions);
    setTables(newTables);
    setFlags(newFlags);

    const prunedRootStateQuery = removeEmptyParams(route?.query);
    const prunedRouteQuery = removeEmptyParams(routeQuery);
    const dateAvailable = !prunedRootStateQuery?.to
        && !prunedRootStateQuery?.from
        && !prunedRouteQuery?.to
        && !prunedRouteQuery?.from
        && dateRange?.from
        && dateRange?.to;

    // Checks if the query changed or if there are selected date range and no query
    if (
      router
        && (!isEqual(prunedRootStateQuery, prunedRouteQuery)
          || !isEqual(prunedRouteQuery, { from: dateRange.from, to: dateRange.to })
          || dateAvailable
        )
    ) {
      const { mergedTempFilters } = storeToRefs(useFilters());

      const newQuery = {
        ...prunedRouteQuery,
        ...mergedTempFilters.value,
        from: dateRange?.from,
        to: dateRange?.to,
      };

      if (selectedMyJobs) {
        newQuery.owned = selectedMyJobs;
      }

      router.push({
        query: newQuery,
      }).catch(() => true); // ignore duplicate navigation error due to global filter selection
    }
  };

  const resetReportingModule = () => {
    setWidgets([]);
    setTotals({});
    setLabelFormats([]);
    setTables({});
    setFlags({});
  };

  const loadPage = async ({
    router,
    routeQuery,
    selectedFilters,
    selectedDateRange,
    page,
    sort,
    selectedMyJobs,
    searchTerm,
    selectedSubMenuOption,
    resetData,
  }) => {
    /* handle loading behaviors */
    useAppLoading().setLoading({
      pageLoading: true, filtersLoading: true, listLoading: true, halfTopBarLoading: true,
    });

    // Handle persisting and setting internal default
    if (showInternalDefault.value && [
      'compliance-report', 'easy-wins-report', 'confidence-gap-report', 'usage',
    ].includes(router.currentRoute.value.name)
    && !navigatedToDefaultExternalPageViaHistory) {
      // eslint-disable-next-line no-param-reassign
      if (!routeQuery || isEmpty(routeQuery)) routeQuery = { internal: 'external' };
      // eslint-disable-next-line no-param-reassign
      else routeQuery = { ...routeQuery, internal: routeQuery.internal || 'external' };

      showInternalDefault.value = false;
    } else if (!showInternalDefault.value && ![
      'compliance-report', 'easy-wins-report', 'confidence-gap-report', 'usage',
    ].includes(router.currentRoute.value.name)) {
      showInternalDefault.value = true;
    }

    navigatedToDefaultExternalPageViaHistory = false;

    /* reset search term */
    if (searchTerm) useFilters().setSearchTerm('');

    try {
      if (router.currentRoute.value.name === 'executive-insights') {
        await useExecutiveInsights().loadExecutiveInsights({
          selectedDateRange,
          routeQuery,
          router,
          selectedFilters,
          selectedMyJobs,
        });
      }

      /* handle page specific requests and logic */
      if (router.currentRoute.value.name === 'usage') {
        await useUsage().getPlatformUsage({
          routeQuery,
          selectedDateRange,
          selectedFilters,
          page,
          router,
          selectedMyJobs,
        });
      }

      if ([
        'compliance-report', 'easy-wins-report', 'confidence-gap-report',
      ].includes(router.currentRoute.value.name)) {
        const actionableInsightTableLabels = {
          'compliance-report': 'Non-Compliant Open Jobs',
          'easy-wins-report': 'Easy Win Open Jobs',
          'confidence-gap-report': 'Open Jobs Missing Job Content',
        };

        const skeletonLoadersTimeout = setTimeout(() => {
          setLabelsForLoading({
            widgetLabels: [],
            tableLabel: actionableInsightTableLabels[router.currentRoute.value.name] || '',
          });
          // add intermediate loading state to filter bar
          useAppLoading().setLoading({ halfTopBarLoading: false });
        }, 4000);

        const {
          getEasyWinsReport,
          getComplianceReport,
          getConfidenceGapReport,
        } = useActionableInsights();

        if (router.currentRoute.value.name === 'compliance-report') {
          await getComplianceReport({
            routeQuery,
            selectedFilters,
            page,
            sort: sort || selectedSubMenuOption || '-severity',
            router,
            selectedMyJobs,
            resetData,
          });
        }

        if (router.currentRoute.value.name === 'easy-wins-report') {
          await getEasyWinsReport({
            routeQuery,
            selectedDateRange,
            selectedFilters,
            page,
            sort: sort || selectedSubMenuOption || '-severity',
            router,
            selectedMyJobs,
            resetData,
          });
        }

        if (router.currentRoute.value.name === 'confidence-gap-report') {
          await getConfidenceGapReport({
            routeQuery,
            selectedDateRange,
            selectedFilters,
            page,
            sort: sort || selectedSubMenuOption || '-severity',
            router,
            selectedMyJobs,
            resetData,
          });
        }

        clearTimeout(skeletonLoadersTimeout);
        setLabelsForLoading({});
      }

      if (dashboardsPages.includes(router.currentRoute.value.name)) {
        await loadDashboardsPageData({
          selectedDateRange,
          page: router.currentRoute.value.name,
          routeQuery,
          router,
          selectedFilters,
          selectedMyJobs,
        });
      }
    } catch (error) {
      // Make sure we don't show our internal api information to the user
      const safeError = getSafeError(error);

      useAppAlerts().addAlert({
        message: `Unable to load ${startCase(router?.currentRoute?.value?.name || 'page')} - ${safeError}`,
        thisPageOnly: true,
      });

      resetReportingModule();

      throw error;
    } finally {
      useAppLoading().setLoading({
        pageLoading: false, filtersLoading: false, listLoading: false, halfTopBarLoading: false,
      });
    }
  };

  const unlockReports = async () => {
    try {
      useAppLoading().setLoading({
        pageLoading: true, filtersLoading: true, halfTopBarLoading: true,
      });

      const {
        analytics_premium: newAnalyticsPremium,
        analytics_premium_seconds_left: newAnalyticsPremiumSecondsLeft,
      } = await startReportingFreeTrial();

      useInsightsPermissions().setReportingStatus({
        newAnalyticsPremium,
        newAnalyticsPremiumSecondsLeft,
      });
    } catch (error) {
      useAppAlerts().addAlert({
        message: `Unable to start free trial - ${error.message}`,
        thisPageOnly: true,
      });
      throw error;
    } finally {
      useAppLoading().setLoading({
        filtersLoading: false, pageLoading: false, halfTopBarLoading: false,
      });
    }
  };

  const submitInsightsShareForm = async () => {
    const insightsShareFormStore = useInsightsShareForm();
    const { setBackendErrors } = insightsShareFormStore;
    try {
      useAppLoading().setLoading({ modalLoading: true });

      const { values } = storeToRefs(insightsShareFormStore);
      const { userIds, shareLink: url, note } = values.value;

      const {
        success,
        failed,
        succeeded,
        errors,
        code,
      } = await sendDashboardShareLink({ userIds, note, url });

      if (success) {
        useAppAlerts().addAlert({
          message: 'Email has been sent',
          type: 'success',
          thisPageOnly: true,
        });
        useModals().setModal({ field: 'dashboard', value: false });
        useInsightsShareForm().resetForm();
        updateEmails({ sent: [], failed: [] });
      }

      if (!success && succeeded && failed) {
        updateEmails({ succeeded, failed });
      }

      if (code && code === 'validation_failed') {
        setBackendErrors(errors);
      }
    } catch (error) {
      useAppAlerts().addAlert({
        message: `Share failed - ${error.message}`,
        thisPageOnly: true,
      });
      throw error;
    } finally {
      useAppLoading().setLoading({ modalLoading: false });
    }
  };

  return {
    // State
    labelsForLoading,
    reportingMessage,
    dismissTrialBanner,
    widgets,
    tables,
    totals,
    selectedTable,
    labelFormats,
    displayOptions,
    flags,
    emailStatus,
    showInternalDefault,

    // Getters
    widgetsWithClasses,
    emptyTableData,
    emptyWidgetData,
    emptyData,

    // Synchronous Actions
    setReportingMessage,
    setDismissTrialBanner,
    setWidgets,
    setTables,
    setTotals,
    setLabelFormats,
    setDisplayOptions,
    resetDashboardsPage,
    setSelectedTable,
    updateEmails,
    setShowInternalDefault,

    // Asynchronous Actions
    loadPage,
    loadDashboardsPageData,
    unlockReports,
    resetReportingModule,
    submitInsightsShareForm,
  };
});
