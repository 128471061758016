/**
 * Quill supports up to 8 levels of list indentation
 *
 * @type {number}
 */
const MAX_QUILL_INDENTATION = 8;

/**
 * remove wrapping <ul> and </ul>
 *
 * @param {string} html
 * @returns {string} See explanation above
 */
const removeOuterUlTag = (html) => html.replace(/^<ul>/, '').replace(/<\/ul>$/, '');

/**
 * split quill list html into a list of <li> tags
 *
 * @param {string} html
 * @returns {Array.<string>} See explanation above
 */
const splitIntoLiTagArray = (html) => html.split(/(<li.+?<\/li>)/).filter(Boolean);

/**
 * convert '<li class="ql-indent-2">bar</li>'
 * to { indent: 2, content: 'bar', children: [] }
 *
 * @param {string} li
 * @returns {{indent: number, content: string, children: *[]}} See explanation above
 */
const parseIndentedLiTag = (li) => {
  const [, , indent, content] = li.match(/<li(\s+class="ql-indent-(\d)")?>(.+)<\/li>/) || [];
  return { indent: Number(indent || '0'), content, children: [] };
};

/**
 * If `items` is
 * [
 *   { indent: 1, content: 'foo', children: [] },
 *   { indent: 4, content: 'bar', children: [] },
 *   { indent: 1, content: 'baz', children: [] },
 * ]
 * it will be modified to:
 * [
 *   { indent: 0, content: '', children: [] },
 *   { indent: 1, content: 'foo', children: [] },
 *   { indent: 2, content: '', children: [] },
 *   { indent: 3, content: '', children: [] },
 *   { indent: 4, content: 'bar', children: [] },
 *   { indent: 1, content: 'baz', children: [] },
 * ]
 *
 * @param {Array.<{indent: number, content: string, children: *[]}>} items
 */
const fillIndentedListGaps = (items) => {
  if (items[0]?.indent > 0) {
    items.unshift({ indent: 0, content: '', children: [] });
  }
  for (let i = 0; i < items.length - 1; i++) {
    const thisItem = items[i];
    const nextItem = items[i + 1];
    if (nextItem.indent > thisItem.indent + 1) {
      items.splice(i + 1, 0, { indent: thisItem.indent + 1, content: '', children: [] });
    }
  }
};

/**
 * if `items` is [
 *   { indent: 0, content: 'f<em>o</em>o', children: [] },
 *   { indent: 1, content: '', children: [] },
 *   { indent: 2, content: 'bar', children: [] },
 * ]
 * then `moveIndentedListItemsToParent(items, 2)`
 * will move all `indent === 2` items to its parent
 * [
 *   { indent: 0, content: 'f<em>o</em>o', children: [] },
 *   { indent: 1, content: '', children: [
 *      { indent: 2, content: 'bar', children: [] },
 *   ]},
 *   null,
 * ]
 *
 * @param {Array.<{indent: number, content: string, children: *[]}>} items
 * @param {number} level
 */
const moveIndentedListItemsToParent = (items, level) => {
  let parent = null;
  items.forEach((item, index) => {
    if (!item) {
      return;
    }
    if (item.indent < level) {
      parent = item;
    } else if (item.indent === level) {
      parent.children.push(item);
      // eslint-disable-next-line no-param-reassign
      items[index] = null;
    }
  });
};

/**
 * If `items` is
 * [
 *   { indent: 0, content: 'foo', children: [
 *     { indent: 1, content: '', children: [
 *       { indent: 2, content: 'bar', children: [] },
 *     ]},
 *   ]},
 * ]
 * the result will be:
 * <li>foo
 *   <ul>
 *     <li>
 *       <ul>
 *         <li>bar</li>
 *       </ul>
 *     </li>
 *   </ul>
 * <li>
 *
 * @param {Array.<{indent: number, content: string, children: *[]}>} items
 * @param {number} depth
 * @returns {string} See explanation above
 */
const generateDeepNestedListHtml = (items, depth = 0) => {
  // infinite loop protection
  if (depth > 10) {
    return '';
  }

  return items
    .filter((item) => item && (item.content || item.children.length > 0))
    .map((item) => {
      if (item.children.length === 0) {
        return `<li>${item.content}</li>`;
      }
      const childrenHtml = generateDeepNestedListHtml(item.children, depth + 1);
      return `<li>${item.content}<ul>${childrenHtml}</ul></li>`;
    })
    .join('');
};

/**
 * convert quill indented li tags to proper nested ul tags
 * if `html` is
 * <ul>
 *   <li>foo</li>
 *   <li class=ql-indent-1>bar</li>
 * </ul>
 *
 * the result will be
 * <ul>
 *   <li>
 *     foo
 *     <ul>
 *       <li>bar</li>
 *     </ul>
 *   </li>
 * </ul>
 *
 * @param {string} html
 * @returns {string} See explanation above
 */
export const toNestedList = (html) => {
  if (!html.startsWith('<ul')) {
    return html;
  }

  const noUlHtml = removeOuterUlTag(html);
  const liTags = splitIntoLiTagArray(noUlHtml);

  if (liTags.length === 0) {
    return html;
  }

  // convert ['<li>f<em>o</em>o</li>', '<li class="ql-indent-2">bar</li>'] to object array:
  // [
  //   { indent: 0, content: 'f<em>o</em>o', children: [] },
  //   { indent: 2, content: 'bar', children: [] },
  // ]
  const listItems = liTags.map(parseIndentedLiTag);

  // fill indentation gaps:
  // [
  //   { indent: 0, content: 'f<em>o</em>o', children: [] },
  //   { indent: 1, content: '', children: [] },
  //   { indent: 2, content: 'bar', children: [] },
  // ]
  fillIndentedListGaps(listItems);

  // transform to a tree structure:
  // [
  //   { "indent":0, "content":"f<em>o</em>o", "children":[
  //     {"indent":1, "content":"", "children":[
  //       {"indent":2, "content":"bar", "children":[]}
  //     ]}
  //   ]}
  // ]
  for (let level = MAX_QUILL_INDENTATION; level >= 1; level--) {
    moveIndentedListItemsToParent(listItems, level);
  }

  // generate nested list html
  return `<ul>${generateDeepNestedListHtml(listItems)}</ul>`;
};

/**
 * convert nested list back to quill indented list
 *
 * @param {string} html
 * @returns {string} See explanation above
 */
export const toIndentedList = (html) => {
  if (!html.startsWith('<ul')) {
    return html;
  }

  const noUlHtml = removeOuterUlTag(html);
  const tagList = noUlHtml.split(/(<\/?(?:ul|li)>)/).filter(Boolean);

  const result = [];
  let nestedLevel = 0;
  tagList.forEach((tag) => {
    // skip LI
    if (tag === '<li>' || tag === '</li>') {
      return;
    }

    if (tag === '<ul>') {
      // each <ul> indicates new nested list
      nestedLevel++;
    } else if (tag === '</ul>') {
      // each </ul> indicates nested list is over
      nestedLevel--;
    } else {
      // any other non-empty string is LI content
      result.push(nestedLevel
        ? `<li class="ql-indent-${nestedLevel}">${tag}</li>`
        : `<li>${tag}</li>`);
    }
  });

  return `<ul>${result.join('')}</ul>`;
};

export const fixQuillNestedBlocks = ({ fields = [] } = {}) => {
  fields.forEach(({ sections = [] } = {}) => {
    sections.forEach(({ blocks = [] } = {}) => {
      blocks.forEach((block) => {
        const { html } = block;
        if (html.includes('<li class="ql-indent')) {
          // eslint-disable-next-line no-param-reassign
          block.html = toNestedList(html);
        }
      });
    });
  });
  return { fields };
};
