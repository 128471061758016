import { useAppAuthData } from '@/stores/app/authData';
import { cloneDeep } from 'lodash-es';

export const getSafeError = (error) => (error.message.includes('reqdb') ? 'Internal Server Error' : error.message);

export const dashboardsPages = [
  'hiring-dynamics',
  'requisition-efficiency',
  'candidate-pool-metrics',
  'overall-report',
  'inbound-report',
  'drop-off',
  'pass-through',
  'outbound-report',
  'gender-report',
  'hiring-report',
];

export const mergeFilters = (tempFilters) => {
  const filters = {};
  tempFilters.forEach((filter) => {
    if (filter.value.length) {
      filters[filter.id] = filter.value.map((value) => value.id);
    }
  });
  return filters;
};

export const isError = (widget) => {
  if (widget.id === 'gender-distribution-of-hires') {
    return !useAppAuthData().authData.canSeeGenderReport;
  }

  return false;
};

export const combineOtherAndUnknownCategories = (categories) => {
  const other = (categories || []).find((category) => category.source_category === 'other');
  const unknown = (categories || []).find((category) => category.source_category === 'unknown');

  if (other && unknown) {
    const combinedSources = other.sources.concat(unknown.sources);

    return categories.map((category) => {
      if (category.source_category === 'other') {
        return { ...category, sources: combinedSources };
      }

      if (category.source_category === 'unknown') {
        return null;
      }

      return category;
    }).filter(Boolean);
  }
  return categories;
};

export const combineDeclinedAndUnknown = (data, isTableGenderData) => {
  const newData = cloneDeep(data); // Prevents mutation of the store data
  const { declined, unknown } = newData;

  delete newData.declined; // eslint-disable-line no-param-reassign
  delete newData.unknown; // eslint-disable-line no-param-reassign

  const undefinedDataset = {
    review: (declined?.review || 0) + (unknown?.review || 0),
    screen: (declined?.screen || 0) + (unknown?.screen || 0),
    assessment: (declined?.assessment || 0) + (unknown?.assessment || 0),
    offer: (declined?.offer || 0) + (unknown?.offer || 0),
    hired: (declined?.hired || 0) + (unknown?.hired || 0),
  };

  const undefinedDatasetKey = isTableGenderData ? 'genderUndefined' : 'raceUndefined';

  const unsortedData = {
    ...newData,
    [undefinedDatasetKey]: undefinedDataset,
  };
  const unsortedKeys = Object.keys(unsortedData);

  // sort by review count highest to lowest
  const sortedKeys = unsortedKeys.sort((a, b) => {
    const aCount = unsortedData[a].review || 0;
    const bCount = unsortedData[b].review || 0;

    return bCount - aCount;
  });

  const sortedData = {};
  sortedKeys.forEach((key) => {
    sortedData[key] = unsortedData[key];
  });

  return sortedData;
};
