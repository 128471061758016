export default {
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#4745D5', // used by Vuetify default vars

          primaryColor: '#4745D5', // primaryblue
          primaryColorDarken20: '#3937AA',
          primaryColorLighten20: '#6C6ADD',
          primaryColorLighten40: '#918FE6',
          primaryColorLighten60: '#B5B5EE',
          primaryColorLighten80: '#DADAF7',
          primaryColorLighten90: '#EDECFB',
          primaryColorLighten95: '#F6F6FD',

          secondaryColor: '#1F2D43', // midnightblue
          secondaryColorLighten20: '#4C5769',
          secondaryColorLighten40: '#79818E',
          secondaryColorLighten60: '#A5ABB4',
          secondaryColorLighten80: '#d2d5d9',
          secondaryColorLighten90: '#E9EAEC',
          secondaryColorLighten95: '#F4F4F6',
          secondaryColorLighten98: '#FBFBFB',

          error: '#DB4346', // is used by Vuetify default vars

          valenciaRed: '#DB4346', // error
          valenciaRedLighten20: '#DB4447',
          valenciaRedLighten40: '#DD4D4F',
          valenciaRedLighten60: '#EB9496',
          valenciaRedLighten80: '#F8DBDC',

          success: '#0BB88B', // is used by Vuetify's default vars

          caribbeanGreen: '#0BB88B', // success
          caribbeanGreenLighten20: '#3CC6A2',
          caribbeanGreenLighten60: '#9DE3D1',
          caribbeanGreenLighten80: '#CEF1E8',

          energyYellow: '#FCD252', // warning
          energyYellowLighten20: '#FDDB75',
          energyYellowLighten60: '#FEEDBA',
          energyYellowLighten80: '#FEF6DC',

          turquoiseBlue: '#77DBE5',
          turquoiseBlueLighten20: '#92E2EA',
          turquoiseBlueLighten60: '#C9F1F5',
          turquoiseBlueLighten80: '#E4F8FA',

          newOrange: '#FF6A5B',
          newOrangeLighten20: '#F79B6C',
          newOrangeLighten60: '#FBCDB5',
          newOrangeLighten80: '#FDE6DA',

          violetPurple: '#5F306E',
          violetPurpleLighten20: '#804194',
          violetPurpleLighten40: '#AA6BBE',
          violetPurpleLighten60: '#CCA6D8',
          violetPurpleLighten80: '#EEE1F2',

          pureWhite: '#FFFFFF',

          zirconGrey: '#DDDFE3',
          zirconGreyLighten60: '#f1f2f4',
          zirconGreyLighten80: '#f8f9f9',

          lightSeaGreen: '#1D9C9E',
          lightSeaGreenLighten20: '#4AB0B1',
          lightSeaGreenLighten60: '#A5D7D8',
          lightSeaGreenLighten80: '#D2EBEC',

          pacificBlue: '#0095DD',
          pacificBlueLighten20: '#33AAE4',
          pacificBlueLighten60: '#99D5F1',
          pacificBlueLighten80: '#CCEAF8',

          chromeYellow: '#FFA600',
          chromeYellowLighten20: '#FFB74E',
          chromeYellowLighten60: '#F5D0A0',
          chromeYellowLighten80: '#FAEAD1',

          tomatoOrange: '#FF6642',

          rosePink: '#FF1979',
          rosePinkLighten20: '#FF368A',
          rosePinkLighten40: '#FF539B',
          rosePinkLighten60: '#FF8CBC',
          rosePinkLighten80: '#FFC6DE',

          vividPink: '#E61895',
          vividPinkLighten20: '#FF368A',
          vividPinkLighten40: '#FF539B',
          vividPinkLighten60: '#FF8CBC',
          vividPinkLighten80: '#FFC6DE',

          darkOrchid: '#8A2EC3',
          darkOrchidLighten20: '#A156CF',
          darkOrchidLighten40: '#B77DDA',
          darkOrchidLighten60: '#D4B1E9',
          darkOrchidLighten80: '#E2CBF0',

          watermelonRed: '#FF405E',

          royalFuscia: '#CC16B1',

          royalBlue: '#2865EC',

          carrotOrange: '#FF8621',

          flameOrange: '#EB4729',

          skeletonGray: '#D5D4DA',

          jaffaOrange: '#F58247',

          // New Colors
          peach: '#F95D6A',

          goldenYellow: '#FFA600',

          ultraViolet: '#58508D',

          charcoal: '#34495E',

          plum: '#9B4F96',

          lightPlum: '#A05195',

          yinmnBlue: '#2F4B7C',

          crimson: '#DC143C',

          forestGreen: '#228B22',

          purple: '#800080',

          skyBlue: '#87CEEB',

          goldenRod: '#DAA520',

          coral: '#FF7F50',

          teal: '#008080',

          seaGreen: '#008D55',

          basicBlue: '#0072FE',

          basicYellow: '#ECA533',

          basicRed: '#DF0000',
        },
      },
    },
  },
};
