import {
  mdiDotsVertical,
  mdiExclamation,
  mdiInformationOutline,
  mdiPlus,
  mdiMinus,
  mdiChevronRight,
  mdiCheckBold,
  mdiMagnify,
  mdiMenuUp,
  mdiChevronLeft,
  mdiChevronDown,
  mdiMenuDown,
  mdiContentCopy,
  mdiMenuRight,
  mdiChevronUp,
  mdiAlertCircle,
  mdiCloseCircleOutline,
  mdiArrowRight,
  mdiClose,
  mdiRhombus,
  mdiCheckboxMarkedCircle,
  mdiPoll,
  mdiCircleSmall,
  mdiCloseCircle,
  mdiCheck,
  mdiPower,
  mdiOpenInNew,
  mdiPencilOutline, // DPA temp
  mdiAccountGroup, // DPA temp
  mdiBagChecked, // DPA temp
  mdiCogOutline, // DPA temp
  mdiArrowLeft, // DPA temp
  mdiFlipToFront, // DPA temp
  mdiCircleOutline, // DPA temp
  mdiCheckCircle, // DPA temp
} from '@mdi/js';

export default {
  'dots-vertical': mdiDotsVertical,
  'information-outline': mdiInformationOutline,
  plus: mdiPlus,
  minus: mdiMinus,
  exclamation: mdiExclamation,
  'chevron-right': mdiChevronRight,
  'chevron-left': mdiChevronLeft,
  'check-bold': mdiCheckBold,
  magnify: mdiMagnify,
  'menu-up': mdiMenuUp,
  'menu-down': mdiMenuDown,
  'content-copy': mdiContentCopy,
  'menu-right': mdiMenuRight,
  'chevron-down': mdiChevronDown,
  'chevron-up': mdiChevronUp,
  'alert-circle': mdiAlertCircle,
  'close-circle-outline': mdiCloseCircleOutline,
  'arrow-right': mdiArrowRight,
  close: mdiClose,
  rhombus: mdiRhombus,
  'checkbox-marked-circle': mdiCheckboxMarkedCircle,
  poll: mdiPoll,
  'circle-small': mdiCircleSmall,
  'close-circle': mdiCloseCircle,
  check: mdiCheck,
  power: mdiPower,
  'pencil-outline': mdiPencilOutline,
  'account-group': mdiAccountGroup,
  'bag-checked': mdiBagChecked,
  'cog-outline': mdiCogOutline,
  'open-in-new': mdiOpenInNew,
  'arrow-left': mdiArrowLeft,
  'flip-to-front': mdiFlipToFront,
  'circle-outline': mdiCircleOutline,
  'check-circle': mdiCheckCircle,
};
