const CSRF_TTL = 20 * 60 * 1000; // new csrf token after 20 minutes

let csrf = '';
let csrfTimestamp = 0;
let newTokenFn = async () => {};

export const setCsrfToken = (token) => {
  csrf = token;
  csrfTimestamp = Date.now();
};

export const setNewTokenFn = (fn) => {
  newTokenFn = fn;
};

export const getCsrfToken = () => csrf;

export const getActiveCsrfToken = async () => {
  if (Date.now() - csrfTimestamp > CSRF_TTL) {
    await newTokenFn();
  }

  return csrf;
};
